import React from 'react'

import photo1 from '../assets/insurance.png';
import photo2 from '../assets/career.png';
import photo3 from '../assets/finance.png';

const OtherServices = (props) => {
  return (
    <div name="otherservices" id="otherservices" className="container text-center py-4 divOtherServicesContainer" style={{backgroundColor: props.divcolorchange}}>
      <div className="w-100 d-flex justify-content-start align-content-center">
      <h2 className="d-inline text-start fst-italic fw-bold title">...{props.OtherServices_name}</h2>
      </div>
      <div className="row align-items-center">
      
        <div className="col-md d-flex justify-content-center m-auto p-2">
          <div className="card" style={{width: "18rem",border: "none"}}>
            <img src={photo1} className="card-img-top" alt="New Raj Tyres Tyre INDAG Retreading Plant Insurance Service" />
            <div className="card-body d-flex justify-content-center align-items-center flex-column rounded-bottom-1" style={{backgroundColor: props.changebackground}}>
              <h5 className="card-title fw-bold" style={{color: props.changetext}}>{props.OtherServices_cardTitle1}</h5>
              <p className="card-text lh-sm fs-6 text-center" style={{color: props.changetext}}>
              {props.OtherServices_cardText1}
              </p>
              <a href="/" className="py-1 py-md-2 px-2 px-md-3 m-1 m-md-2 rounded-2 border border-primary">
                {props.OtherServices_details}
              </a>
            </div>
          </div>
        </div>
        <div className="col-md d-flex justify-content-center m-auto p-2">
          <div className="card" style={{width: "18rem",border: "none"}}>
            <img src={photo2} className="card-img-top" alt="New Raj Tyres Tyre INDAG Retreading Plant Career Solutions" />
            <div className="card-body d-flex justify-content-center align-items-center flex-column rounded-bottom-1" style={{backgroundColor: props.changebackground}}>
              <h5 className="card-title fw-bold" style={{color: props.changetext}}>{props.OtherServices_cardTitle2}</h5>
              <p className="card-text lh-sm text-center" style={{color: props.changetext}}>
                {props.OtherServices_cardText2}
              </p>
              <a href="/" className="py-1 py-md-2 px-2 px-md-3 m-1 m-md-2 rounded-2 border border-primary">
              {props.OtherServices_details}
              </a>
            </div>
          </div>
        </div>
        <div className="col-md d-flex justify-content-center m-auto p-2">
          <div className="card" style={{width: "18rem",border: "none"}}>
            <img src={photo3} className="card-img-top" alt="New Raj Tyres Tyre INDAG Retreading Plant Finance Services" />
            <div className="card-body d-flex justify-content-center align-items-center flex-column rounded-bottom-1" style={{backgroundColor: props.changebackground}}>
              <h5 className="card-title fw-bold" style={{color: props.changetext}}>{props.OtherServices_cardTitle3}</h5>
              <p className="card-text lh-sm text-center" style={{color: props.changetext}}>
              {props.OtherServices_cardText3}
              </p>
              <a href="/" className="py-1 py-md-2 px-2 px-md-3 m-1 m-md-2 rounded-2 border border-primary">
              {props.OtherServices_details}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OtherServices

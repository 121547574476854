import React from 'react'

const Footer = () => {
  return (
    <div name="footer" id='footer' className="container-fluid d-flex justify-content-center align-content-center flex-column bg-dark">
      <p className='w-100 text-center my-0'>All Rights are reserved by <a href="/">New Raj Tyre</a>.</p>
      <p className='w-100 text-start my-0 mb-1'>This website is made and maintain by <a href="http://twitter.com/MontygSharma" rel="noreferrer" target='_blank'>Shekhar Ranjan</a>.</p>
    </div>
  )
}

export default Footer

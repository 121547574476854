import React from "react";
import Photo1 from "../assets/engi1.png";
import BrochureHindi from "../assets/brochureHindi.pdf";
import BrochureEnglish from "../assets/brochureEnglish.pdf";

const About = (props) => {

  return (
    <div name="about" id="about" className="container text-center py-4 px-2 divAboutContainer" style={{backgroundColor: props.divcolorchange}}>
      <div className="w-100 d-flex justify-content-start align-content-center">
      <h2 className="d-inline-block text-start fst-italic fw-bold title">...{props.about_name}</h2>
      </div>
      <div className="w-100 flex-column flex-md-row align-items-center d-flex justify-content-space-between aboutFlexDirection">
        <div className="d-inline-block aboutFlexDirectionPhoto">
          <img src={Photo1} className="d-block w-100 object-fit-contain rounded-3" alt="New Raj Tyres Tyre INDAG Retreading Plant" />
        </div>
        <div className="align-items-start d-flex justify-content-center flex-column text-start p-2 aboutFlexDirectionParagraph">
          <p className="w-100 d-block mb-2" style={{color: props.changetext}}>{props.about_descriptionINenglish}</p>
          <div className="w-100 mt-4 text-center p-2 divBrochure">
          <a className="py-1 py-md-2 px-2 px-md-3 m-1 m-md-2 rounded-2 border border-primary brochure" href={BrochureHindi} download="Brochure in Hindi" >ब्रोशर हिंदी में</a>
          <a className="py-1 py-md-2 px-2 px-md-3 m-1 m-md-2 rounded-2 border border-primary brochure" href={BrochureEnglish} download="Brochure in English" >Brochure in English</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

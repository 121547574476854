import React from "react";

const Feedback = (props) => {
  return (
    <div name="contact" id="contact" className="container-fluid text-center p-2 bg-black">
      <div className="align-items-start flex-column flex-md-row d-flex justify-content-space-between p-2 divContactContainer border border-2 border-secondary rounded-1">
        <div className="w-100 divContactLinks">
          <h3 className="fw-bold text-secondary"><u>{props.contact_nameLinks}</u></h3>
          <p className="w-100"><a href="#home" className="anchorTagContact">{props.contact_home}</a></p>
          <p className="w-100"><a href="#about" className="anchorTagContact">{props.contact_about}</a></p>
          <p className="w-100"><a href="#retreading" className="anchorTagContact">{props.contact_retreading}</a></p>
          <p className="w-100"><a href="#otherservices" className="anchorTagContact">{props.contact_other_services}</a></p>
          <p className="w-100"><a href="#feedback" className="anchorTagContact">{props.contact_feedback}</a></p>
        </div>
        <div className="w-100 flex-column d-flex justify-content-space-between align-content-center text-start rounded-3 divContactAddress">
          <h3 className="fw-bold text-secondary"><u>{props.contact_nameAddress}</u></h3>
          <p>{props.contact_nameOfCompany}</p>
          <p>{props.contact_address1}</p>
          <p>{props.contact_address2}</p>
          <p>{props.contact_email_id}</p>
          <p>{props.contact_mob}</p>
        </div>
      </div>
    </div>
  );
};

export default Feedback;

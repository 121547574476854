import React, { useState } from "react";

import Header from "./Starter/Header";
import Tagband from "./Starter/Tagband";
import Home from "./components/Home";
import About from "./components/About";
import Retreading from "./components/Retreading";
import OtherServices from "./components/OtherServices";
import Feedback from "./components/Feedback";
import Footer from "./Starter/Footer";
import Contact from "./components/Contact";
import Services from "./components/Services";
import Certificates from "./components/Certificates";
import textAPI from "./textAPI";

function App() {
  // Calling API
  const [text1, text2, text3, text4, text5, text6, text7, text8, text9] =
    textAPI;

  // Day mode and night mode
  const [mode, setMode] = useState("Night");
  const [changebackground, setChangebackground] = useState(
    (document.body.style.backgroundColor = "white")
  );
  const [changetext, setChangetext] = useState("gray");
  const [divcolorchange, setDivcolorchange] = useState("#DCDBD6");
  const [changeForm_background_color, setChangeForm_background_color] =
    useState("#fff");
  const [text_color_for_navbar, setText_color_for_navbar] = useState("#000");

  // Changing day and night function
  const changebackgroundColor = () => {
    if (mode === "Night") {
      setMode("Day");
      setChangebackground((document.body.style.backgroundColor = "black"));
      setChangetext("white");
      setDivcolorchange("#383838");
      setChangeForm_background_color("#939393");
      setText_color_for_navbar("#fff");
    } else {
      setMode("Night");
      setChangebackground((document.body.style.backgroundColor = "white"));
      setChangetext("gray");
      setDivcolorchange("#DCDBD6");
      setChangeForm_background_color("#fff");
      setText_color_for_navbar("#000");
    }
  };

  // Language Changing Section

  // Language Changing Button
  const [btn, setBtn] = useState("हिंदी");

  // About Page
  const [about_name, setAbout_name] = useState(text1.name);
  const [about_descriptionINenglish, setAbout_descriptionINenglish] = useState(
    text1.descriptionINenglish
  );

  // Certificates Page
  const [certificates_name, setCertificates_name] = useState(text2.name);

  // Retreading Services
  const [services_name, setServices_name] = useState(text3.name);
  const [services_descriptionINenglish, setServices_descriptionINenglish] =
    useState(text3.descriptionINenglish);
  const [
    services_descriptionINenglishPhase1,
    setServices_descriptionINenglishPhase1,
  ] = useState(text3.descriptionINenglishPhase1);
  const [
    services_descriptionINenglishPhase2,
    setServices_descriptionINenglishPhase2,
  ] = useState(text3.descriptionINenglishPhase2);
  const [
    services_descriptionINenglishPhase3,
    setServices_descriptionINenglishPhase3,
  ] = useState(text3.descriptionINenglishPhase3);
  const [
    services_descriptionINenglishPhase4,
    setServices_descriptionINenglishPhase4,
  ] = useState(text3.descriptionINenglishPhase4);
  const [
    services_descriptionINenglishPhase5,
    setServices_descriptionINenglishPhase5,
  ] = useState(text3.descriptionINenglishPhase5);

  // Retreading Page
  const [retreading_name, setRretreading_name] = useState(text5.name);
  const [retreading_descriptionINenglish, setRetreading_descriptionINenglish] =
    useState(text5.descriptionINenglish);
  const [
    retreading_descriptionINenglishPhase1,
    setRetreading_descriptionINenglishPhase1,
  ] = useState(text5.descriptionINenglishPhase1);
  const [
    retreading_descriptionINenglishPhase2,
    setRetreading_descriptionINenglishPhase2,
  ] = useState(text5.descriptionINenglishPhase2);
  const [
    retreading_descriptionINenglishPhase3,
    setRetreading_descriptionINenglishPhase3,
  ] = useState(text5.descriptionINenglishPhase3);

  // Feedback Form
  const [feedback_name, setFeedback_name] = useState(text7.name);
  const [feedback_fullName, setFeedback_fullName] = useState(text7.full_name);
  const [feedback_mobileNumber, setFeedback_mobileNumber] = useState(
    text7.mobile_number
  );
  const [feedback_emailId, setFeedback_emailId] = useState(text7.email_id);
  const [feedback_writeText, setFeedback_writeText] = useState(
    text7.write_text
  );
  const [feedback_submit, setFeedback_submit] = useState(text7.submit_form);

  // Our Other Services
  const [OtherServices_name, setOtherServices_name] = useState(text4.name);
  const [OtherServices_cardTitle1, setOtherServices_cardTitle1] = useState(
    text4.cardTitle1
  );
  const [OtherServices_cardText1, setOtherServices_cardText1] = useState(
    text4.cardText1
  );
  const [OtherServices_cardTitle2, setOtherServices_cardTitle2] = useState(
    text4.cardTitle2
  );
  const [OtherServices_cardText2, setOtherServices_cardText2] = useState(
    text4.cardText2
  );
  const [OtherServices_cardTitle3, setOtherServices_cardTitle3] = useState(
    text4.cardTitle3
  );
  const [OtherServices_cardText3, setOtherServices_cardText3] = useState(
    text4.cardText3
  );
  const [OtherServices_details, setOtherServices_details] = useState(
    text4.details
  );

  // Contact Page
  const [contact_nameLinks, setContact_nameLinks] = useState(text8.nameLinks);
  const [contact_home, setContact_home] = useState(text8.home);
  const [contact_about, setContact_about] = useState(text8.about);
  const [contact_retreading, setContact_retreading] = useState(
    text8.retreading
  );
  const [contact_other_services, setContact_other_services] = useState(
    text8.other_services
  );
  const [contact_feedback, setContact_feedback] = useState(text8.feedback);
  const [contact_nameAddress, setContact_nameAddress] = useState(
    text8.nameAddress
  );
  const [contact_nameOfCompany, setContact_nameOfCompany] = useState(
    text8.nameOfCompany
  );
  const [contact_address1, setContact_address1] = useState(text8.address1);
  const [contact_address2, setContact_address2] = useState(text8.address2);
  const [contact_email_id, setContact_email_id] = useState(text8.email_id);
  const [contact_mob, setContact_mob] = useState(text8.mob);

  // Header Page
  const [header_name, setHeader_name] = useState(text9.name);
  const [header_home, setHeader_home] = useState(text9.home);
  const [header_about, setHeader_about] = useState(text9.about);
  const [header_services, setHeader_services] = useState(text9.services);
  const [header_contact, setHeader_contact] = useState(text9.contact);

  // Language changing function
  const languageChanger = () => {
    if (btn === "ENGLISH") {
      setBtn("हिंदी");

      // About Page
      setAbout_name(text1.name);
      setAbout_descriptionINenglish(text1.descriptionINenglish);

      // Certificates Page
      setCertificates_name(text2.name);

      // Retreading Services
      setServices_name(text3.name);
      setServices_descriptionINenglish(text3.descriptionINenglish);
      setServices_descriptionINenglishPhase1(text3.descriptionINenglishPhase1);
      setServices_descriptionINenglishPhase2(text3.descriptionINenglishPhase2);
      setServices_descriptionINenglishPhase3(text3.descriptionINenglishPhase3);
      setServices_descriptionINenglishPhase4(text3.descriptionINenglishPhase4);
      setServices_descriptionINenglishPhase5(text3.descriptionINenglishPhase5);

      // Retreading Page
      setRretreading_name(text5.name);
      setRetreading_descriptionINenglish(text5.descriptionINenglish);
      setRetreading_descriptionINenglishPhase1(
        text5.descriptionINenglishPhase1
      );
      setRetreading_descriptionINenglishPhase2(
        text5.descriptionINenglishPhase2
      );
      setRetreading_descriptionINenglishPhase3(
        text5.descriptionINenglishPhase3
      );

      // Feedback Form
      setFeedback_name(text7.name);
      setFeedback_fullName(text7.full_name);
      setFeedback_mobileNumber(text7.mobile_number);
      setFeedback_emailId(text7.email_id);
      setFeedback_writeText(text7.write_text);
      setFeedback_submit(text7.submit_form);

      // Our Other Services
      setOtherServices_name(text4.name);
      setOtherServices_cardTitle1(text4.cardTitle1);
      setOtherServices_cardText1(text4.cardText1);
      setOtherServices_cardTitle2(text4.cardTitle2);
      setOtherServices_cardText2(text4.cardText2);
      setOtherServices_cardTitle3(text4.cardTitle3);
      setOtherServices_cardText3(text4.cardText3);
      setOtherServices_details(text4.details);

      // Contact Page
      setContact_nameLinks(text8.nameLinks);
      setContact_about(text8.about);
      setContact_retreading(text8.retreading);
      setContact_other_services(text8.other_services);
      setContact_feedback(text8.feedback);
      setContact_nameAddress(text8.nameAddress);
      setContact_nameOfCompany(text8.nameOfCompany);
      setContact_address1(text8.address1);
      setContact_address2(text8.address2);
      setContact_email_id(text8.email_id);
      setContact_mob(text8.mob);
      setContact_home(text8.home);

      // Header Page
      setHeader_home(text9.home);
      setHeader_about(text9.about);
      setHeader_services(text9.services);
      setHeader_contact(text9.contact);
      setHeader_name(text9.name);
    } else {
      setBtn("ENGLISH");

      // About Page
      setAbout_name(text1.nameINhindi);
      setAbout_descriptionINenglish(text1.descriptionINhindi);

      // Certificates Page
      setCertificates_name(text2.nameINhindi);

      // Retreading Services
      setServices_name(text3.nameINhindi);
      setServices_descriptionINenglish(text3.descriptionINhindi);
      setServices_descriptionINenglishPhase1(text3.descriptionINhindiPhase1);
      setServices_descriptionINenglishPhase2(text3.descriptionINhindiPhase2);
      setServices_descriptionINenglishPhase3(text3.descriptionINhindiPhase3);
      setServices_descriptionINenglishPhase4(text3.descriptionINhindiPhase4);
      setServices_descriptionINenglishPhase5(text3.descriptionINhindiPhase5);

      // Retreading Page
      setRretreading_name(text5.nameINhindi);
      setRetreading_descriptionINenglish(text5.descriptionINhindi);
      setRetreading_descriptionINenglishPhase1(text5.descriptionINhindiPhase1);
      setRetreading_descriptionINenglishPhase2(text5.descriptionINhindiPhase2);
      setRetreading_descriptionINenglishPhase3(text5.descriptionINhindiPhase3);

      // Feedback Form
      setFeedback_name(text7.nameINhindi);
      setFeedback_fullName(text7.full_nameINhindi);
      setFeedback_mobileNumber(text7.mobile_numberINhindi);
      setFeedback_emailId(text7.email_idINhindi);
      setFeedback_writeText(text7.write_textINhindi);
      setFeedback_submit(text7.submit_formINhindi);

      // Our Other Services
      setOtherServices_name(text4.nameINhindi);
      setOtherServices_cardTitle1(text4.cardTitle1INhindi);
      setOtherServices_cardText1(text4.cardText1INhindi);
      setOtherServices_cardTitle2(text4.cardTitle2INhindi);
      setOtherServices_cardText2(text4.cardText2INhindi);
      setOtherServices_cardTitle3(text4.cardTitle3INhindi);
      setOtherServices_cardText3(text4.cardText3INhindi);
      setOtherServices_details(text4.detailsINhindi);

      // Contact Page
      setContact_nameLinks(text8.nameLinksINhindi);
      setContact_about(text8.aboutINhindi);
      setContact_retreading(text8.retreadingINhindi);
      setContact_other_services(text8.other_servicesINhindi);
      setContact_feedback(text8.feedbackINhindi);
      setContact_nameAddress(text8.nameAddressINhindi);
      setContact_nameOfCompany(text8.nameOfCompanyINhindi);
      setContact_address1(text8.address1INhindi);
      setContact_address2(text8.address2INhindi);
      setContact_email_id(text8.email_idINhindi);
      setContact_mob(text8.mobINhindi);
      setContact_home(text8.homeINhindi);

      // Header Page
      setHeader_home(text9.homeINhindi);
      setHeader_about(text9.aboutINhindi);
      setHeader_services(text9.servicesINhindi);
      setHeader_contact(text9.contactINhindi);
      setHeader_name(text9.nameINhindi);
    }
  };

  return (
    <div style={{ backgroundColor: changebackground }}>
      <Header
        changebackground={changebackground}
        changetext={changetext}
        text_color_for_navbar={text_color_for_navbar}
        header_name={header_name}
        header_home={header_home}
        header_about={header_about}
        header_services={header_services}
        header_contact={header_contact}
      />

      <Tagband
        mode={mode}
        changebackground={changebackground}
        changebackgroundColor={changebackgroundColor}
        changetext={changetext}
        divcolorchange={divcolorchange}
        btn={btn}
        languageChanger={languageChanger}
      />

      <Home />

      <About // mode={mode}
        // changebackground={changebackground}
        // changebackgroundColor={changebackgroundColor}
        changetext={changetext}
        divcolorchange={divcolorchange}
        about_name={about_name}
        about_descriptionINenglish={about_descriptionINenglish}
      />

      <Retreading // mode={mode}
        // changebackground={changebackground}
        changetext={changetext}
        divcolorchange={divcolorchange}
        // changebackgroundColor={changebackgroundColor}

        retreading_name={retreading_name}
        retreading_descriptionINenglish={retreading_descriptionINenglish}
        retreading_descriptionINenglishPhase1={
          retreading_descriptionINenglishPhase1
        }
        retreading_descriptionINenglishPhase2={
          retreading_descriptionINenglishPhase2
        }
        retreading_descriptionINenglishPhase3={
          retreading_descriptionINenglishPhase3
        }
      />

      <Services // mode={mode}
        changebackground={changebackground}
        changebackgroundColor={changebackgroundColor}
        changetext={changetext}
        divcolorchange={divcolorchange}
        services_name={services_name}
        services_descriptionINenglish={services_descriptionINenglish}
        services_descriptionINenglishPhase1={
          services_descriptionINenglishPhase1
        }
        services_descriptionINenglishPhase2={
          services_descriptionINenglishPhase2
        }
        services_descriptionINenglishPhase3={
          services_descriptionINenglishPhase3
        }
        services_descriptionINenglishPhase4={
          services_descriptionINenglishPhase4
        }
        services_descriptionINenglishPhase5={
          services_descriptionINenglishPhase5
        }
      />

      <OtherServices
        mode={mode}
        changebackground={changebackground}
        // changebackgroundColor={changebackgroundColor}
        changetext={changetext}
        divcolorchange={divcolorchange}
        OtherServices_name={OtherServices_name}
        OtherServices_cardTitle1={OtherServices_cardTitle1}
        OtherServices_cardText1={OtherServices_cardText1}
        OtherServices_cardTitle2={OtherServices_cardTitle2}
        OtherServices_cardText2={OtherServices_cardText2}
        OtherServices_cardTitle3={OtherServices_cardTitle3}
        OtherServices_cardText3={OtherServices_cardText3}
        OtherServices_details={OtherServices_details}
      />

      <Feedback
        changetext={changetext}
        divcolorchange={divcolorchange}
        changebackground={changebackground}
        changeForm_background_color={changeForm_background_color}
        feedback_name={feedback_name}
        feedback_fullName={feedback_fullName}
        feedback_mobileNumber={feedback_mobileNumber}
        feedback_emailId={feedback_emailId}
        feedback_writeText={feedback_writeText}
        feedback_submit={feedback_submit}
      />

      <Certificates
        changetext={changetext}
        divcolorchange={divcolorchange}
        certificates_name={certificates_name}
      />

      <Contact
        changetext={changetext}
        divcolorchange={divcolorchange}
        contact_nameLinks={contact_nameLinks}
        contact_home={contact_home}
        contact_about={contact_about}
        contact_retreading={contact_retreading}
        contact_other_services={contact_other_services}
        contact_feedback={contact_feedback}
        contact_nameAddress={contact_nameAddress}
        contact_nameOfCompany={contact_nameOfCompany}
        contact_address1={contact_address1}
        contact_address2={contact_address2}
        contact_email_id={contact_email_id}
        contact_mob={contact_mob}
      />

      <Footer />
    </div>
  );
}

export default App;

import React from "react";
import Photo1 from "../assets/INDAG_poster.jpg";
import Photo2 from "../assets/INDAG_safe.jpg";
import Photo3 from "../assets/INDAG_tyre.jpg";

const Home = () => {
  return (
    <div 
      name="home" 
      id="home"
      className="carousel slide d-flex justify-content-center align-content-center"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="4000">
          <img src={Photo1} className="d-block w-100" alt="New Raj Tyres Tyre INDAG Retreading Plant" />
        </div>
        <div className="carousel-item" data-bs-interval="4000">
          <img src={Photo2} className="d-block w-100" alt="New Raj Tyres Tyre INDAG Retreading Plant" />
        </div>
        <div className="carousel-item" data-bs-interval="4000">
          <img src={Photo3} className="d-block w-100" alt="New Raj Tyres Tyre INDAG Retreading Plant" />
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#home"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon inline-flex bg-black rounded-4" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#home"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon inline-flex bg-black rounded-4" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Home;

import React from "react";
import Photo1 from "../assets/logo1.png";

const Header = (props) => {
  return (
    <nav name="header" className="navbar navbar-expand-lg" style={{backgroundColor: props.changebackgroundColor}}>
      <div className="container-fluid">
          <img src={Photo1} className="object-fit-contain logoPicture" alt="New Raj Tyres Tyre INDAG Retreading Plant" />
        <a href="https://www.newrajtyres.in" className="navbar-brand fw-bolder" style={{color:props.text_color_for_navbar}}>
        {props.header_name}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation" 
          style={{color:props.text_color_for_navbar}}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a href="#home" className="nav-link active" aria-current="page" style={{color:props.text_color_for_navbar}}>
              {props.header_home}
              </a>
            </li>
            <li className="nav-item">
              <a href="#about" className="nav-link" style={{color:props.text_color_for_navbar}}>
              {props.header_about}
              </a>
            </li>
            <li className="nav-item">
              <a href="#services" className="nav-link" style={{color:props.text_color_for_navbar}}> 
              {props.header_services}
              </a>
            </li>
            <li className="nav-item">
              <a href="#contact" className="nav-link" style={{color:props.text_color_for_navbar}}>
              {props.header_contact}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;

import React from "react";
import Photo1 from "../assets/indag.jpg";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const Services = (props) => {

  return (
    <div name="services" id="services" className="container text-center py-4 px-2 divServicesContainer"  style={{backgroundColor: props.divcolorchange}}>
      <div className="w-100 d-flex justify-content-start align-content-center">
      <h2 className="d-inline-block fst-italic fw-bold title">
        ...{props.services_name}
      </h2>
      </div>
      <div className="w-100 align-items-center d-flex justify-content-space-between servicesFlexDirection">
        <div className="align-items-center d-flex justify-content-center servicesFlexDirectionPhoto">
          <img
            src={Photo1}
            className="d-block w-100 object-fit-contain rounded-3"
            alt="New Raj Tyres Tyre INDAG Retreading Plant Insurance Service"
          />
        </div>
        <div className="w-100 align-items-start d-flex justify-content-center flex-column text-start p-2 servicesFlexDirectionParagraph">
          <p className="d-block servicesFlexDirectionParagraph_Offer_Services" style={{color: props.changetext}}><b>{props.services_descriptionINenglish} </b></p>
          <p className="w-100" style={{color: props.changetext}}>
            <ArrowOutwardIcon fontSize="small" />{props.services_descriptionINenglishPhase1}
          </p>
          <p className="w-100" style={{color: props.changetext}}>
            <ArrowOutwardIcon fontSize="small" />{props.services_descriptionINenglishPhase2} 
          </p>
          <p className="w-100" style={{color: props.changetext}}>
            <ArrowOutwardIcon fontSize="small" /> {props.services_descriptionINenglishPhase3}
          </p>
          <p className="w-100" style={{color: props.changetext}}>
            <ArrowOutwardIcon fontSize="small" /> {props.services_descriptionINenglishPhase4}
          </p>
          <p className="w-100" style={{color: props.changetext}}>
            <ArrowOutwardIcon fontSize="small" /> {props.services_descriptionINenglishPhase5}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;

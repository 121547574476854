import React from "react";

import photo1 from "../assets/instance1.jpg";
import photo2 from "../assets/indag1.png";
import photo3 from "../assets/msme1.jpg";
import photo4 from "../assets/gst1.jpg";

const Certificates = (props) => {
  return (
    <div name="certificates" id="certificates" className="container py-4 divCertificatesContainer" style={{backgroundColor: props.divcolorchange}}>
      <div className="w-100 d-flex justify-content-start align-content-center div1">
        <h2 className="d-inline-block fst-italic fw-bold title">
          ...{props.certificates_name}
        </h2>
      </div>
      <div className="w-100 div2">
        <img
          src={photo1}
          className="object-fit-contain m-sm-1"
          alt="New Raj Tyres Tyre INDAG Retreading Plant 100% Guarantee Certificate"
        />
        <img
          src={photo2}
          className="object-fit-contain m-1"
          alt="New Raj Tyres Tyre INDAG Retreading Plant"
        />
        <img
          src={photo3}
          className="object-fit-contain m-1"
          alt="New Raj Tyres Tyre INDAG Retreading Plant MSME"
        />
        <img
          src={photo4}
          className="object-fit-contain m-1"
          alt="New Raj Tyres Tyre INDAG Retreading Plant 100% GST Certificate"
        />
      </div>
    </div>
  );
};

export default Certificates;

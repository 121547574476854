// import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const Tagband = (props) => {
  return (
    <div name="tagband" className="divTagband">
      <div
        className="divTagbandBigScreen"
        style={{ backgroundColor: "#cc0836" }}
      >
        <div className="SocialIcons w-25 d-flex justify-content-start align-items-center rounded-2 bordern border-primary">
          <a
            href="http://instagram.com/newrajtyres"
            rel="noreferrer"
            target="_blank"
            className="ikons"
          >
            <InstagramIcon />
          </a>
          <a
            href="http://facebook.com/newrajtyres"
            rel="noreferrer"
            target="_blank"
            className="ikons"
          >
            <FacebookIcon />
          </a>
          <a
            href="http://twitter.com/NewRajTyres"
            rel="noreferrer"
            target="_blank"
            className="ikons"
          >
            <TwitterIcon />
          </a>
        </div>
        <div className="translator rounded-2">
          <button
            type="button"
            onClick={props.languageChanger}
            className="rounded-2"
          >
            <b>{props.btn}</b>
          </button>
        </div>
        <div className="translator rounded-2">
          <button
            type="button"
            onClick={props.changebackgroundColor}
            className="rounded-2"
          >
            <b>{props.mode}</b>
          </button>
        </div>
      </div>
      <div className="divTagbandSmallScreen">
        <div className="smallIcon d-flex justify-content-center align-items-center">
          <RadioButtonCheckedIcon className="text-white m-auto" />
        </div>
        <div className="SocialIcons">
          <a href="http://instagram.com/newrajtyres" rel="noreferrer" target="_blank">
            <InstagramIcon className="ikons text-white" />
          </a>
          <a href="http://facebook.com/newrajtyres" rel="noreferrer" target="_blank">
            <FacebookIcon className="ikons text-white" />
          </a>
          <a href="http://twitter.com/NewRajTyres" rel="noreferrer" target="_blank">
            <TwitterIcon className="ikons text-white" />
          </a>
        </div>
        <div className="DualMode rounded-2">
          <button
            type="button"
            onClick={props.languageChanger}
            className="rounded-2"
          >
            <b>{props.btn}</b>
          </button>
        </div>
        <div className="translator rounded-2">
          <button
            type="button"
            onClick={props.changebackgroundColor}
            className="rounded-2"
          >
            <b>{props.mode}</b>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Tagband;

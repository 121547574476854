const textAPI = [
    {
        id: 1,
        name: "Know About Us",
        descriptionINenglish: `In 1980, 'Raj Tyres' plant was established by Munna Ji(Raj Kumar Singh) and in 2019, it was re-established this with new name 'New Raj Tyres' by Monu Ji(R. Raj), son of Munna Ji with advance technology like automatic and latest radial machines. We do retreading of tires and we are in this field from 33 years. For more details, download the brochures:`,

        nameINhindi: "हमारे बारे में जानें",
        descriptionINhindi: `1980 में, 'राज टायर्स' प्लांट की स्थापना मुन्ना जी (राज कुमार सिंह) द्वारा की गई थी और 2019 में, मुन्ना जी के बेटे मोनू जी (आर. राज) द्वारा इसे नए नाम 'न्यू राज टायर्स' के साथ फिर से स्थापित किया गया। स्वचालित और नवीनतम रेडियल मशीनों जैसी उन्नत तकनीक। हम टायरों की रीट्रेडिंग करते हैं और हम 33 वर्षों से इस क्षेत्र में हैं।अधिक जानकारी के लिए, ब्रोशर डाउनलोड करें:`,
    },
    {
        id: 2,
        name: "Our Certificates",
        nameINhindi: "हमारे प्रमाणपत्र",
    },
    {
        id: 3,
        name: "Retreading Services",
        descriptionINenglish: `We offer many Retreading Services:`,
        descriptionINenglishPhase1: ` Price as per the quality
            standards.`,
        descriptionINenglishPhase2: ` We provide you the right tread
            pattern.`,
        descriptionINenglishPhase3: `Standard workmanship process.`,
        descriptionINenglishPhase4: `Best quality retreading.`,
        descriptionINenglishPhase5: `We provide you warranty.`,

        nameINhindi: `रीट्रेडिंग सेवाएँ`,
        descriptionINhindi: `हम कई रीट्रेडिंग सेवाएँ प्रदान करते हैं:`,
        descriptionINhindiPhase1: `कीमत गुणवत्ता मानकों के अनुसार।`,
        descriptionINhindiPhase2: `हम आपको सही ट्रेड पैटर्न प्रदान करते हैं।`,
        descriptionINhindiPhase3: `मानक कारीगरी प्रक्रिया।`,
        descriptionINhindiPhase4: `सर्वोत्तम गुणवत्ता वाली रीट्रेडिंग।`,
        descriptionINhindiPhase5: `हम आपको वारंटी प्रदान करते हैं।`,
    },
    {
        id: 4,
        name: "Our Other Services",

        cardTitle1: "Raj Insurance Services",
        cardText1: "Better insurance starts here for you and your family with protection, you can rely on.",

        cardTitle2: "Raj Career Solutions",
        cardText2: "A complete educational solutions for your children's bright and prosperous future.",

        cardTitle3: "Raj Finance",
        cardText3: "Financial Strength is the cornerstone of your success and to advance your business.",

        details: "Details",

        nameINhindi: "हमारी अन्य सेवाएँ",

        cardTitle1INhindi: "राज बीमा सेवाएँ",
        cardText1INhindi: "आपके और आपके परिवार के लिए सुरक्षा के साथ बेहतर बीमा यहीं से शुरू होता है, जिस पर आप भरोसा कर सकते हैं।",

        cardTitle2INhindi: "राज कैरियर सॉलूशन्स",
        cardText2INhindi: "आपके बच्चों के उज्ज्वल और समृद्ध भविष्य के लिए एक संपूर्ण शैक्षिक समाधान।",

        cardTitle3INhindi: "राज फाइनेंस",
        cardText3INhindi: "वित्तीय मजबूती आपकी सफलता और आपके व्यवसाय को आगे बढ़ाने की आधारशिला है।",

        detailsINhindi: "विवरण",
    },
    {
        id: 5,
        name: "What is Retreading",
        descriptionINenglish: `
        Retreading is a green and sustainable alternative to new tyres. It is a technical process of high precision and craftsmanship used to rejuvenate an old worn-out tyre into a renewed one. After a vehicle has covered certain kilometres, , the top surface of a tyre called tread wears out, leaving you with two choices – either go for a new tyre or replace it with a new tread thus renewing the old worn-worn tyre. Retreads are safe because:`,
        descriptionINenglishPhase1: `Retreads are as safe as new tyres.`,
        descriptionINenglishPhase2: `They are widely used by the fleet owners.`,
        descriptionINenglishPhase3: `Even the aircraft use retreaded tyres.`,
        
        nameINhindi: "रीट्रेडिंग क्या है?",
        descriptionINhindi: `रीट्रेडिंग नए टायरों का एक हरित और टिकाऊ विकल्प है। यहउच्च परिशुद्धता और शिल्प कौशल की एक तकनीकी प्रक्रिया है पुराने घिसे-पिटे टायर को फिर से नया रूप दें। एक वाहन के बाद कुछ किलोमीटर की दूरी तय की है, टायर की ऊपरी सतह को कहा जाता है चलना घिस जाता है, जिससे आपके पास दो विकल्प बचते हैं - या तो नया खरीदें
        इसे टायर करें या इसे नए ट्रेड से बदलें जिससे पुराना घिसा-पिटा नवीनीकरण हो जाए थका देना। रीट्रेड्स सुरक्षित हैं क्योंकि:`,
        descriptionINhindiPhase1: `रीट्रेड्स नए टायरों की तरह ही सुरक्षित हैं।`,
        descriptionINhindiPhase2: `इन्हें बेड़े मालिकों द्वारा व्यापक रूप से उपयोग किया जाता है।`,
        descriptionINhindiPhase3: `यहां तक कि विमानों में भी पुराने टायरों का इस्तेमाल होता है।`,
    },
    {
        id: 6,
        name: "Retreading Services",

        descriptionINenglish: `We offer many Retreading Services:`,
        descriptionINenglishPhase1: `Price as per the quality
        standards.`,
        descriptionINenglishPhase2: `We provide you the right tread pattern.`,
        descriptionINenglishPhase3: `Standard workmanship process.`,
        descriptionINenglishPhase4: `Best quality retreading.`,
        descriptionINenglishPhase5: `We provide you warranty.`,

        descriptionINhindi: `हम कई रीट्रेडिंग सेवाएँ प्रदान करते हैं:`,
        descriptionINhindiPhase1: `गुणवत्ता के अनुसार कीमत
          मानक.`,
        descriptionINhindiPhase2: `हम आपको सही चाल प्रदान करते हैं नमूना।`,
        descriptionINhindiPhase3: `मानक कारीगरी प्रक्रिया.`,
        descriptionINhindiPhase4: `सर्वोत्तम गुणवत्ता वाली रीट्रेडिंग.`,
        descriptionINhindiPhase5: `हम आपको वारंटी प्रदान करते हैं।`,
    },
    {
        id: 7,
        name: 'This is Feedback Form',
        full_name: 'Full Name',
        mobile_number: 'Mobile Number',
        email_id: 'Email-id',
        write_text: 'Write your text here...',
        submit_form: 'Submit', 

        nameINhindi: 'यह फीडबैक फॉर्म है',
        full_nameINhindi: 'पूरा नाम',
        mobile_numberINhindi: 'मोबाइल नंबर',
        email_idINhindi: 'ईमेल-आईडी',
        write_textINhindi: 'यहाँ लिखें...',
        submit_formINhindi: 'भेजें',
    },
    {
        id: 8,

        nameLinks: "Links",
        home: "Home",
        about: "About",
        retreading: "Retreading",
        other_services: "Other Services",
        feedback: "Feedback",

        nameLinksINhindi: "लिंक्स",
        homeINhindi: "होम",
        aboutINhindi: "अबाउट",
        retreadingINhindi: "रीट्रेडिंग",
        other_servicesINhindi: "अन्य सेवाएं",
        feedbackINhindi: "प्रतिक्रिया",

        nameAddress: "Address",
        nameOfCompany: "New Raj Tyres",
        address1: "Bypass Road, Near Sapna Cinema Mode, Arrah",
        address2: "Bhojpur, Bihar, Pin Code: 802301",
        email_id: "Email-ID: help@newrajtyre.com",
        mob: "Mob: +918002007128, +919431052736",

        nameAddressINhindi: "पता",
        nameOfCompanyINhindi: "न्यू राज टायर्स",
        address1INhindi: "बाईपास रोड, सपना सिनेमा मोड के पास, आरा",
        address2INhindi: "भोजपुर, बिहार, पिन कोड: 802301",
        email_idINhindi: "ईमेल-आईडी: help@newrajtyre.com",
        mobINhindi: "मोब:- +918002007128, +919431052736",
    },
    {
        id: 9,

        name: "New Raj Tyres",
        home: "Home",
        about: "About",
        services: "Services",
        contact: "Contact",

        nameINhindi: "न्यू राज टायर्स",
        homeINhindi: "होम",
        aboutINhindi: "अबाउट",
        servicesINhindi: "सर्विसेज",
        contactINhindi: "संपर्क",
    }
];

export default textAPI;
import React from "react";
import Photo1 from "../assets/feedback1.png";

const Feedback = (props) => {
  return (
    <div name="feedback" id="feedback" className="container text-center py-4 px-2 divFeedbackContainer" style={{backgroundColor: props.divcolorchange}}>
    <div className="w-100 d-flex justify-content-start align-content-center">
      <h2 className="d-inline-block text-start fst-italic fw-bold title">
        ...{props.feedback_name}
      </h2>
    </div>
      <div className="align-items-start flex-column flex-md-row d-flex justify-content-evenly p-2">
        <div className="w-100 d-flex align-items-center justify-content-center rounded-3 divFeedback">
          <img
            src={Photo1}
            className="d-block object-fit-contain rounded-3 imgFeedback"
            alt="New Raj Tyres Tyre INDAG Retreading"
          />  
        </div>
        <div className="w-100 flex-column d-flex justify-content-center align-content-center p-2 pt-4 pt-md-0 divFeedbackDiv">
          <div className="mb-3 d-flex justify-content-start flex-column">
            <input
              type="name"
              className="form-control"
              id="FormControlInput1"
              placeholder={props.feedback_fullName} 
              style={{backgroundColor: props.changeForm_background_color, border: "none" }}
            />
          </div>
          <div className="mb-3">
            <input
              type="tel"
              className="form-control"
              id="FormControlInput1"
              placeholder={props.feedback_mobileNumber}
              style={{backgroundColor: props.changeForm_background_color, border: "none" }}
            />
          </div>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              id="FormControlInput1"
              placeholder={props.feedback_emailId}
              style={{backgroundColor: props.changeForm_background_color, border: "none" }}
            />
          </div>
          <div className="mb-3">
            <textarea
              className="form-control"
              id="FormControlTextarea1"
              rows="3"
              placeholder={props.feedback_writeText}
              style={{backgroundColor: props.changeForm_background_color, border: "none" }}
            ></textarea>
          </div>
          <div className="mb-1 d-flex justify-content-start">
            <button type="submit" className="py-1 py-md-2 px-2 px-md-3 m-1 m-md-2 rounded-2 border border-primary">
              {props.feedback_submit}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feedback; 

import React from "react";
import Photo1 from "../assets/tyre.jpg";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const Retreading = (props) => {

  return (
    <div name="retreading" className="container text-center py-4 px-2 divRetreadingContainer" style={{backgroundColor: props.divcolorchange}}>
      <div className="w-100 d-flex justify-content-start align-content-center">
      <h2 className="d-inline position-relative top-50 text-start fst-italic fw-bold title">
        ...{props.retreading_name}
      </h2>
      </div>
      <div name="retreading" className="w-100 flex-column flex-md-row align-items-center d-flex justify-content-space-between aboutFlexDirection">
        <div className="align-items-start d-flex justify-content-center flex-column text-start p-2 retreadingFlexDirectionParagraph">
          <p className="d-block" style={{color: props.changetext}}>
            {props.retreading_descriptionINenglish}</p>
          <p className="w-100 pt-2" style={{color: props.changetext}}>
            <ArrowOutwardIcon fontSize="small" /> {props.retreading_descriptionINenglishPhase1}
          </p>
          <p className="w-100 pt-1" style={{color: props.changetext}}>
            <ArrowOutwardIcon fontSize="small" /> {props.retreading_descriptionINenglishPhase2}
          </p>
          <p className="w-100 pt-1" style={{color: props.changetext}}>
            <ArrowOutwardIcon fontSize="small" /> {props.retreading_descriptionINenglishPhase3}
          </p>
          
        </div>
        <div className="d-inline-block mt-3 retreadingFlexDirectionPhoto">
            <img
              src={Photo1}
              className="d-block w-100 object-fit-contain rounded-3"
              alt="New Raj Tyres Tyre INDAG Retreading Plant"
            />
        </div>
      </div>
    </div>
  );
};

export default Retreading;
